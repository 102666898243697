<!--  -->
<template>
    <div class="shouAbout">
      <div class="headBox">
          <div class="shouhome-header">
              <div class="shouhome-header-box">
              <div class="shouhome-header-left">
                  <img src="../assets/some/logo2.png" alt="">
                  <div>JUNKO NEW ENERGY</div>
              </div>
              <div class="shouhome-header-center"></div>
              <div class="shouhome-header-newright">
                  <div class="shouhome-header-right" @click="btnFlagFun()">
                  <div :class="btnFlag == true?'menulineactive':'middleline'"></div> 
                  </div>
              </div>

              </div>
              <div class="shouhome-header-line" :class="{ 'shouhomeDownBox': btnFlag }">
              <div class="shouhome-header-line-left" :class="item.status == true ? 'newBackColor':''"
              v-for="item in navList" :key="item.id" @click="selectFun(item)">
                  <div class="shouhome-header-line-left-box">
                      {{item.name}}
                  <!-- <van-icon name="arrow" v-if="item.childList.length > 0 && item.status == false"></van-icon>
                  <van-icon name="arrow-down" v-if="item.childList.length > 0 && item.status == true"></van-icon> -->
                  </div>
                  <!-- <template v-if="item.childList.length > 0 && item.status == true">
                  <div v-for="it in item.childList" :key="it.id" @click.stop="childselectFun(item,it)"
                  class="shouhome-header-line-left-box-child"
                  :class="it.status == true ? 'newBackColor':''">{{it.name}}</div>
                  </template> -->
              </div>

              </div>
          </div>
      </div>
  
      <div class="shouAbout-content">
        <img class="shouAbout-content-swiperImg" src="../assets/new/g1.jpg" />
      </div>
      <div class="shouAbout-equipment">
        <!-- <div class="shouAbout-box-tltle"> Equipment </div> -->
        <div class="shouAbout-font">
          <strong>Shanghai Junko New Energy Technology Co. , Ltd.,</strong> located at Xinda Building, No. 322 Xiancan Road, Changning District, Shanghai, is a high-tech enterprise specializing in the research and development, production, and sales of induction heating technology and semiconductor materials. The company has invested heavily in technology research and development and has demonstrated outstanding professional capabilities in production and sales.
          <div class="homeweCont-font">
            <strong>R&D and Production Base</strong>: The company has established an advanced R&D and production base, equipped with high-standard testing and inspection facilities, as well as modern production lines, ensuring product quality and production efficiency.
          </div>
          <div class="homeweCont-font">
            <strong>Contributions in the Field of Semiconductor Materials</strong>: In the research and development of semiconductor materials, the company is committed to promoting the green energy revolution in the country through innovative material technology and assisting in achieving energy-saving and emission reduction goals. Our goal is to continuously improve technology to bring users a lighter and better future life experience, and to make a positive contribution to global sustainable development.
          </div>

          <div class="homeweCont-font">
            <strong>Leader in Induction Heating Technology</strong>: In the development of induction heating equipment, the company adheres to "energy-saving and efficiency" and "stability and reliability" as core values, continuously promoting the development of product automation, intelligence, and unmanned functions. We are at the forefront of domestic induction heating technology, dedicated to providing customers with more efficient and reliable heating solutions through technological innovation.
          </div>
          <div class="homeweCont-font">
            <strong>Comprehensive Service System</strong>: We provide a comprehensive pre-sales, sales, and after-sales service to ensure that our products and technologies can create greater value for our customers. Through meticulous customer service, we have established good customer relationships and won widespread recognition in the market.
          </div>
          <div class="homeweCont-font">
            Shanghai Junko New Energy Technology Co., Ltd., driven by innovation, with quality as its lifeblood, and service as its guarantee, is committed to becoming a leading enterprise in the field of new energy technology.
          </div>
        </div>
      </div>
        
        <!-- <div class="homeweBanner">
          <img src="../assets/some/29.jpg" />
        </div> -->

        <div class="shouAbout-box">
            <div class="shouAbout-box-tltle">Corporate culture</div>
            <div class="shouAbout-box-content">
              <div class="shouAbout-box-content-qiyewenhuaBox">
                <img src="../assets/some/10.png" />
                <div>
                  <div class="shouAbout-box-content-qiyewenhuaBox-title">Innovation</div>
                  <div class="shouAbout-box-content-qiyewenhuaBox-cont">Innovation-driven 、Technology pioneer</div>
                </div>
              </div>
              <div class="shouAbout-box-content-qiyewenhuaBox newqiyewenhuaBox">
                <img src="../assets/some/10.png" />
                <div>
                  <div class="shouAbout-box-content-qiyewenhuaBox-title">Quality</div>
                  <div class="shouAbout-box-content-qiyewenhuaBox-cont">Quality-oriented 、 Exquisite Craftsmanship</div>
                </div>
              </div>
              <div class="shouAbout-box-content-qiyewenhuaBox">
                <img src="../assets/some/10.png" />
                <div>
                  <div class="shouAbout-box-content-qiyewenhuaBox-title">Sustainability</div>
                  <div class="shouAbout-box-content-qiyewenhuaBox-cont">Green manufacturing、Environmentally friendly</div>
                </div>
              </div>
            </div>
        </div>


        <!-- <div class="homeweCont newBoHeBox">
            <div class="homeweCont-title newBoHe">Partners</div>
            <img src="../assets/some/13.png"/>
        </div> -->
        <!-- <div class="shouAbout-box">
            <div class="shouAbout-box-tltle">Business partner</div>
            <div class="shouAbout-box-newpartner">
              <img src="../assets/some/13.png"/>
            </div>
        </div> -->


        <!-- <div class="homeWoBox newshouContact">
          <div class="homeWoBox-title">Request for Quotation</div>
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="95px" class="demo-ruleForm">
              <el-form-item label="Name" prop="name">
                  <el-input v-model="ruleForm.name"></el-input>
              </el-form-item>
              <el-form-item label="Mobile" prop="phone">
                  <el-input v-model="ruleForm.phone"></el-input>
              </el-form-item>
              <el-form-item label="Email" prop="email">
                  <el-input v-model="ruleForm.email"></el-input>
              </el-form-item>
              <el-form-item label="Country" prop="country">
                  <el-input v-model="ruleForm.country"></el-input>
              </el-form-item>
              <el-form-item label="Leaving a message" prop="message" class="ceshidiv">
                  <el-input v-model="ruleForm.message" type="textarea" :rows="2"></el-input>
              </el-form-item>
              <el-form-item>
                  <el-button type="primary" @click="submitForm('ruleForm')">Submit</el-button>
              </el-form-item>
          </el-form>
        </div> -->
        <div class="shouhome-footer">
          <div class="shouhome-footer-box newApplication"> | Request for Quotation </div>
          <div class="shouhome-footer-message">
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="84px" class="demo-ruleForm">
                <el-form-item label="Name" prop="name">
                    <el-input v-model="ruleForm.name"></el-input>
                </el-form-item>
                <el-form-item label="Mobile" prop="phone">
                    <el-input v-model="ruleForm.phone"></el-input>
                </el-form-item>
                <el-form-item label="Email" prop="email">
                    <el-input v-model="ruleForm.email"></el-input>
                </el-form-item>
                <el-form-item label="Country" prop="country">
                    <el-input v-model="ruleForm.country"></el-input>
                </el-form-item>
                <el-form-item label="Leaving a message" prop="message" class="ceshidiv">
                    <el-input v-model="ruleForm.message" type="textarea" :rows="2"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitForm('ruleForm')">Submit</el-button>
                </el-form-item>
            </el-form>
          </div>
        </div>





      <Bottom></Bottom>
    </div>
  </template>
  
  <script>
  import Bottom from "../components/shoubottom";
  import {Dialog} from 'vant';
  import { APILogin } from "../api/APILogin";
  export default {
    name: "shouAbout",
    components: {

      Bottom,
    },
    data() {
      return {
        btnFlag: false,
        navList: [
            {
                id: 1,
                name: 'Home',
                status: false,
                url: require('@/assets/shouji/1.png'),
                urls: require('@/assets/shouji/2.png'),
                childList: [],
                path:'/shouhome',
            },
            {
                id: 2,
                name: 'About Us',
                status: true,
                url: require('@/assets/shouji/3.png'),
                urls: require('@/assets/shouji/4.png'),
                childList: [],
                path:'/shouAbout',
            },
            {
                id: 3,
                name: 'Product Center',
                status: false,
                url: require('@/assets/shouji/5.png'),
                urls: require('@/assets/shouji/6.png'),
                path:'/shouChan',
                childList: [
                    {
                        id: 31,
                        name: 'Equipment',
                        status: true,
                        path:'/shouChan',
                    },{
                        id: 32,
                        name: 'SIC',
                        status: false,
                        path:'/shouChan',
                    }
                ],
            }, {
                id: 4,
                name: 'Application Cases',
                status: false,
                url: require('@/assets/shouji/7.png'),
                urls: require('@/assets/shouji/8.png'),
                childList: [],
                path:'/shouYing',
            },{
                id: 5,
                name: 'Contact Us',
                status: false,
                url: require('@/assets/shouji/9.png'),
                urls: require('@/assets/shouji/10.png'),
                childList: [],
                path:'/shouContact',
            },
        ],

        ruleForm: {
          name: '',
          country: '',
          email: '',
          message: '',
          phone: '',
        },
        rules: {
          name: [
            { required: true, message: '请输入名称', trigger: 'blur' },
          ],
          phone: [
            { required: true, message: '请输入电话', trigger: 'blur' },
          ],
          email: [
            { required: true, message: '请输入邮箱', trigger: 'blur' },
          ],
          message: [
            { required: true, message: '请输入留言', trigger: 'blur' },
          ],
        },
      }
    },
    // 监听属性 类似于data概念
    computed: {},
    // 监控data中的数据变化
    watch: {
      // '$route'(newRoute) {
      //   //console.log('路由变化了》》》》', newRoute);
      //   //this.monitoredRoute = newRoute; // 将新的路由信息保存到组件的monitoredRoute属性中
      //   // 执行其他操作或调用其他方法
      // },
    },
    filters: {},
    //方法集合
    methods: {
      // 菜单折叠
      btnFlagFun() {
          this.btnFlag = !this.btnFlag
      },
      // 选择菜单
      selectFun(item) {
          //console.log('111>>'+ JSON.stringify(item))
          this.navList.map(it => {
              if (it.id == item.id) {
                  it.status = true
                  this.$router.push(it.path)
              }else{
                  it.status = false
              }
          })
      },
      // 子选择菜单
      childselectFun(val,obj) {
          //console.log('222>>'+ JSON.stringify(val))
          //console.log('333>>'+ JSON.stringify(obj))
          this.navList.map(item => {
              if (item.id == val.id && item.childList.length > 0) {
                  item.status = true
                  item.childList.map(it=>{
                      if(it.id == obj.id) {
                          it.status = true
                          this.$router.push(it.path)
                      }else {
                          it.status = false
                      }
                  })
              }else{
                  item.status = false
              }
          })
      },

      // 提交留言
      submitForm() {
        this.$refs.ruleForm.validate(valid => {
          if (valid) {
            // 验证通过，提交表单
            //console.log('提交的数据：', this.ruleForm);
            APILogin.messageAdd({
                name: this.ruleForm.name, 
                country: this.ruleForm.country,
                email: this.ruleForm.email, 
                message: this.ruleForm.message,
                phone: this.ruleForm.phone, 
            }).then(res => {
                Dialog.alert({
                    message: 'Thank you for your message. Someone will contact you later',
                })
                console.log("res>>>>" + JSON.stringify(res.data))
            }).catch(fail => {
                console.log("fail>>>>" + JSON.stringify(fail))
            }) 
          } else {
            // 验证失败
            console.log('验证失败！');
            return false;
          }
        });
      },
    },
    // 生命周期 - 创建完成（可以访问当前this实例）
    created() {
      document.title = "JUNKO NEW ENERGY"
    },
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {
       // 监听路由变化
      // this.$watch(() => this.$route, (to, from) => {
      //   //console.log('路由变化了》》》》', to, from);
      //   // 这里可以根据路由变化执行相应的逻辑
      // });
    },
    beforeCreate() {}, // 生命周期 - 创建之前
    beforeMount() {}, // 生命周期 - 挂载之前
    beforeUpdate() {}, // 生命周期 - 更新之前
    updated() {}, // 生命周期 - 更新之后
    beforeDestroy() {}, // 生命周期 - 销毁之前
    destroyed() {}, // 生命周期 - 销毁完成
    activated() {}, // 如果页面有keep-alive缓存功能，这个函数会触发
  };
  </script>
  <style lang="less" scoped src="@/styles/pc/home.less"></style>
  <style lang="less" scoped src="@/styles/pc/homewe.less"></style>
  <style lang="less" scoped src="@/styles/phone/shouhome.less"></style>
  <style lang="less" scoped src="@/styles/phone/shouAbout.less"></style>
  <style lang="less">
      .el-button--primary {
      width: 100%
    }
      .ceshidiv {
      .el-form-item__label {
        line-height: 16px;
        margin-top: 10px;
      }
    }
  .newshouContact {
    width: 80% !important;
    margin-left: 0 !important;
    margin-top: 20px !important;
    margin: 30px auto !important;
    padding-right: 20px !important;
    margin-bottom: 20px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    .el-button--primary {
      width: 100%
    }
}
  </style>
  
  
  
  